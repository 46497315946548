import React from 'react';
import styles from './Partners.module.scss';
import Container from '../../../templates/Container';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Color images
import Logo_federacyia from '../../../../assets/logo/partners/partner-federacyia.svg';
import Logo_mitropolyia from '../../../../assets/logo/partners/mitropolia.png';
import Logo_krasgorpark from '../../../../assets/logo/partners/partner-krasgorpark.svg';
import Logo_airport from '../../../../assets/logo/partners/aeroport.png';
import Logo_ClearWater from '../../../../assets/logo/partners/partner-clear-water-biege.png';
import Logo_tic from '../../../../assets/logo/partners/tic.svg';

// Black images
import Logo_federacyia_black from '../../../../assets/logo/partners/partner-federacyia-black.png';
import Logo_mitropolyia_black from '../../../../assets/logo/partners/mitropolia-black.png';
import Logo_krasgorpark_black from '../../../../assets/logo/partners/partner-krasgorpark-black.png';
import Logo_airport_black from '../../../../assets/logo/partners/aeroport-black.png';
import Logo_ClearWater_black from '../../../../assets/logo/partners/partner-clear-water-black.png';
import Logo_tic_black from '../../../../assets/logo/partners/tic-black.svg';

const Partners = () => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <section className={styles.partners}>
      <h2 className='visually-hidden'>Партнеры</h2>
      <Container>
        {!colorThemeRedux && <div className={styles.separator} />}
        <ul className={styles.wrapper}>
          <li
            className={
              styles.item +
              ' ' +
              (colorThemeRedux ? styles.lowVisibleVersion : '')
            }
          >
            <Link to='https://mchf.ru/' target='_blank'>
              <img
                src={colorThemeRedux ? Logo_federacyia_black : Logo_federacyia}
                alt='федерация мужских хоров'
              />
            </Link>
          </li>

          <li
            className={
              styles.item +
              ' ' +
              (colorThemeRedux ? styles.lowVisibleVersion : '')
            }
          >
            <Link to='https://kerpc.ru/' target='_blank'>
              <img
                src={
                  colorThemeRedux ? Logo_mitropolyia_black : Logo_mitropolyia
                }
                alt='красноярская митрополия'
              />
            </Link>
          </li>

          <li
            className={
              styles.item +
              ' ' +
              (colorThemeRedux ? styles.lowVisibleVersion : '')
            }
          >
            <Link to='https://www.kja.aero/' target='_blank'>
              <img
                src={colorThemeRedux ? Logo_airport_black : Logo_airport}
                alt='Международный аэропорт Красноярск'
              />
            </Link>
          </li>

          <li
            className={
              styles.item +
              ' ' +
              (colorThemeRedux ? styles.lowVisibleVersion : '')
            }
          >
            <Link to='https://krasgorpark.ru/' target='_blank'>
              <img
                src={
                  colorThemeRedux ? Logo_krasgorpark_black : Logo_krasgorpark
                }
                alt='Красгорпарк'
              />
            </Link>
          </li>

          <li
            className={
              styles.item +
              ' ' +
              // eslint-disable-next-line no-useless-concat
              (colorThemeRedux ? styles.lowVisibleVersion : '') + ' ' + "water"
            }
          >
            <Link to='https://sibwater.ru/' target='_blank'>
              <img
                src={colorThemeRedux ? Logo_ClearWater_black : Logo_ClearWater}
                alt='Чистая вода Сибири'
              />
            </Link>
          </li>

          {/* Туристский информационный центр */}
          <li
            className={
              styles.item +
              ' ' +
              (colorThemeRedux ? styles.lowVisibleVersion : '')
            }
          >
            <Link to='https://visitsiberia.info/' target='_blank'>
              <img
                src={colorThemeRedux ? Logo_tic_black : Logo_tic}
                alt='Туристский информационный центр'
              />
            </Link>
          </li>
        </ul>
      </Container>
    </section>
  );
};

export default Partners;
