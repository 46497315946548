/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import styles from "./Media.module.scss";
import PageTemplate from "../../templates/PageTemplate";
import Container from "../../templates/Container";
import Head from "../../elements/Head/Head";
import { getPhoto } from "../../../redux/requests/getPhoto";
import { getVideo } from "../../../redux/requests/getVideo";
import Image from "./Image";
import { useSelector } from "react-redux";

import { ReactComponent as Arrow } from "../../../assets/svg/Arrow_up.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";

const Media = () => {
  const [serverAnswer, setServerAnswer] = useState();
  const [photo, setPhoto] = useState();
  const [years, setYears] = useState();
  const [activeYear, setActiveYear] = useState();
  const [days, setDays] = useState();
  const [activeDay, setActiveDay] = useState();
  const [ativeMediaCategory, setActiveMediaCategory] = useState("Фотогалерея");
  const [videos, setVideos] = useState();
  const [currentPhoto, setCurrentPhoto] = useState();

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  useEffect(() => {
    getPhoto().then((res) => {
      setServerAnswer(res.results);

      // определяем массив лет и первый год массива автоматически делаем активным
      const yearsArray = [];
      res.results.forEach((item) => {
        !yearsArray.includes(item.year) && yearsArray.push(item.year);
      });

      // yearsArray.reverse();
      setYears(yearsArray);
      setActiveYear(yearsArray[0]);
    });
  }, []);

  useEffect(() => {
    getVideo().then((res) => {
      setVideos(res.results);
    });
  }, []);

  useEffect(() => {
    if (!serverAnswer) return;
    if (!activeYear) return;
    // получаем все даты актиного года при услоии, что есть актиный год и пришел ответ с сервера
    const daysInActiveYear = serverAnswer.filter(
      (item) => item.year === activeYear
    );

    // определяем массив дней и первый день массива автоматически делаем активным
    const daysArray = [];

    daysInActiveYear.forEach((item) => {
      !daysArray.includes(item.title) && daysArray.push(item.title);
    });
    setDays(daysArray);
    setActiveDay(daysArray[0]);
  }, [activeYear, serverAnswer]);

  useEffect(() => {
    if (!activeDay || !serverAnswer) return;
    const photosInactiveDay = serverAnswer.find(
      (item) => item.title === activeDay
    );
    setPhoto(photosInactiveDay.photos);
  }, [activeDay, serverAnswer]);

  const yearClickHandler = (item) => {
    if (item === activeYear) return;
    setPhoto();
    setActiveYear(item);
  };

  const dayClickHandler = (item) => {
    if (item === activeDay) return;
    setPhoto();
    setActiveDay(item);
  };

  const createMarkup = (text) => {
    return { __html: text };
  };

  const nextPhoto = () => {
    const currentPhotoIndex = photo.findIndex(
      (item) => item.id === currentPhoto.id
    );

    if (currentPhotoIndex == photo.length - 1) {
      setCurrentPhoto(photo[0]);
    } else {
      setCurrentPhoto(photo[currentPhotoIndex + 1]);
    }
  };

  const prevPhoto = () => {
    const currentPhotoIndex = photo.findIndex(
      (item) => item.id === currentPhoto.id
    );

    // eslint-disable-next-line eqeqeq
    if (currentPhotoIndex == 0) {
      setCurrentPhoto(photo[photo.length - 1]);
    } else {
      setCurrentPhoto(photo[currentPhotoIndex - 1]);
    }
  };

  return (
    <>
      <PageTemplate>
        <Head />
        <Container>
          {currentPhoto && (
            <div
              className={styles.currentPhotoModal}
              style={colorThemeRedux ? { backgroundColor: "#000000" } : {}}
            >
              <div
                onClick={() => setCurrentPhoto()}
                style={{
                  position: "absolute",
                  top: 10,
                  right: "5%",
                  cursor: "pointer",
                }}
              >
                <Close />
              </div>
              <div
                style={{
                  transform: "rotate(-90deg)",
                  marginRight: "5%",
                  cursor: "pointer",
                }}
                onClick={prevPhoto}
              >
                <Arrow />
              </div>
              <img
                src={currentPhoto.file}
                alt=""
                style={colorThemeRedux ? { borderColor: "#ffffff" } : {}}
              />
              <div
                style={{
                  transform: "rotate(90deg)",
                  marginLeft: "5%",
                  cursor: "pointer",
                }}
                onClick={nextPhoto}
              >
                <Arrow />
              </div>
            </div>
          )}
          <section
            className={
              styles.media +
              " " +
              (colorThemeRedux ? styles.lowVisibleVersion : "")
            }
          >
            <div className={styles.control}>
              {!colorThemeRedux && <div className={styles.separator} />}

              <div className={styles.control_wrapper}>
                <div
                  className={styles.control_item + " " + styles.photo_control}
                  onClick={() => setActiveMediaCategory("Фотогалерея")}
                >
                  <button
                    className={
                      styles.control_button +
                      " " +
                      (ativeMediaCategory === "Фотогалерея"
                        ? styles.pressed
                        : "")
                    }
                    style={
                      ativeMediaCategory === "Фотогалерея"
                        ? {
                            backgroundColor: "rgba(174, 150, 124, 0.4)",
                            boxShadow:
                              "0 0 0 2px #ae967c, 0 0 0 4px #2e1005, 0 0 7px 3px #e3d1ad",
                          }
                        : {}
                    }
                    type="button"
                  >
                    <span>Фотогалерея</span>
                  </button>

                  {ativeMediaCategory === "Фотогалерея" && (
                    <>
                      <div className={styles.yearsControl}>
                        {years?.map((item, index) => (
                          <div
                            className={styles.dates}
                            key={index}
                            onClick={() => yearClickHandler(item)}
                          >
                            <button
                              className={
                                item === activeYear ? styles.pressed : ""
                              }
                              style={
                                item === activeYear
                                  ? {
                                      backgroundColor:
                                        "rgba(174, 150, 124, 0.4)",
                                      boxShadow:
                                        "0 0 0 2px #ae967c, 0 0 0 4px #2e1005, 0 0 7px 3px #e3d1ad",
                                    }
                                  : {}
                              }
                            >
                              {item}
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className={styles.daysControl}>
                        {days?.map((item, index) => (
                          <div
                            className={styles.dates}
                            key={index}
                            onClick={() => dayClickHandler(item)}
                          >
                            <button
                              className={
                                item === activeDay ? styles.pressed : ""
                              }
                              style={
                                item === activeDay
                                  ? {
                                      backgroundColor:
                                        "rgba(174, 150, 124, 0.4)",
                                      boxShadow:
                                        "0 0 0 2px #ae967c, 0 0 0 4px #2e1005, 0 0 7px 3px #e3d1ad",
                                    }
                                  : {}
                              }
                            >
                              {item}
                            </button>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {/* <div className={styles.dates} /> */}
                </div>

                <div className={styles.control_title}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='60' height='40' style='shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd' viewBox='0 0 12 10'%3e%3cpath d='M4.2 3.5h-.3c0 .2-.2.3-.3.3l-.2-.1c-.3.2-.7.3-1.1.3-.7 0-1.1-.3-1.4-.6l-.4-.2v.2c-.1.1-.3.1-.4 0v-.5l.3-.2-.1-.6.2-1S.6.7.5.7C.6.7.6.9.4.9A.2.2 0 0 1 .1.6C.1.3.3.2.4.2c.3 0 .4.3.5.3.3-.3.9-.6 1.7-.6C3.8 0 4.4.8 5 1.6h.2c.2 0 .4.2.4.4v.3c.3.5.8 1.5 2 1.5 1.9 0 2.2-1.2 2.2-2 0-.6-.5-1.5-1.9-1.5-.4 0-.7 0-1 .2 0 .2 0 .4-.2.4h-.2c-.3.3-.5.8-.5 1.1 0 .5.6 1.4 1.4 1.4.4 0 .5-.4.5-.6-.2.2-.7.7-1.2.3-.3-.4 0-.8 0-1 0 0-.4 0-.4-.4 0-.6.7-.5 1-.5 0-.3.4-.8.5-.8 0 0 .6.5.6.8.2-.2 1 0 1 .4s-.5.5-.5.6c0 0 .6.3.3.7-.5.5-1 .1-1.2 0 0 .2-.1.6-.5.6-1.2 0-1.6-1.1-1.6-1.6v-.5h-.2a.2.2 0 0 1-.2-.2c0-.2.2-.3.3-.3l.4.2.2-.4V.6L6 .5c-.2 0-.4 0-.6.2-.2 0-.4.2-.8.1C4.2.8 4 .2 4 .2l.5.2c.3 0 1-.3 1.4-.2.3 0 .4.1.5.2H6.7C7 .2 7.4 0 8 0c.7 0 1.2.3 1.5.6 0 0 .2-.3.4-.3l.3.2v.1c0 .2 0 .3-.2.3-.1 0-.2 0-.2-.2v.4c.2.4.2.8.2.9l-.2.8c.1 0 .2 0 .3.2.1 0 .2.3 0 .4-.1.2-.3.2-.3 0l-.1-.2-.4.3c-.3.3-.8.5-1.6.5-1.1 0-1.8-.8-2.3-1.6H5c-.3 0-.5-.1-.5-.4 0-.1 0-.2.2-.3-.4-.6-1-1.5-2.2-1.5-1.8 0-2 1.2-2 2 0 .6.4 1.6 1.8 1.6.4 0 .7-.1 1-.3 0-.2 0-.3.2-.3h.1c.4-.4.6-.9.6-1.2 0-.5-.5-1.4-1.4-1.4-.4 0-.5.4-.5.6.2-.2.7-.5 1.2 0 .3.3-.3.7-.3.7s.5.1.5.6c0 .4-.8.5-1 .3 0 .4-.5.9-.6.9-.1 0-.6-.6-.6-.8-.2 0-.9 0-.9-.5s.3-.5.5-.5c-.1-.1-.4-.6 0-1s1 .1 1 .3c.1-.2.2-.7.7-.7C4 .5 4.4 1.6 4.4 2v.5l.3-.2s.2.1.2.3c0 .2-.2.3-.3.3l-.4-.2-.3.6.7-.1c.4 0 1 .4 1.2.4l.3-.1h.1s-.3.5-.8.5c-.6 0-.9-.5-1.2-.5zm-2-.8c.2 0 .5-.9.1-.9s-.2.8-.1.8zm5.7-.5s.2-.9-.1-.9c-.4 0-.2.9 0 .9z' style='fill:%23e3d1ad;fill-opacity:.501961'/%3e%3c/svg%3e"
                      alt=""
                    />
                    <h1 className={styles.section_title}>Медиа</h1>
                    <img
                      src="data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='60' height='40' style='shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd' viewBox='0 0 12 10'%3e%3cpath d='M4.2 3.5h-.3c0 .2-.2.3-.3.3l-.2-.1c-.3.2-.7.3-1.1.3-.7 0-1.1-.3-1.4-.6l-.4-.2v.2c-.1.1-.3.1-.4 0v-.5l.3-.2-.1-.6.2-1S.6.7.5.7C.6.7.6.9.4.9A.2.2 0 0 1 .1.6C.1.3.3.2.4.2c.3 0 .4.3.5.3.3-.3.9-.6 1.7-.6C3.8 0 4.4.8 5 1.6h.2c.2 0 .4.2.4.4v.3c.3.5.8 1.5 2 1.5 1.9 0 2.2-1.2 2.2-2 0-.6-.5-1.5-1.9-1.5-.4 0-.7 0-1 .2 0 .2 0 .4-.2.4h-.2c-.3.3-.5.8-.5 1.1 0 .5.6 1.4 1.4 1.4.4 0 .5-.4.5-.6-.2.2-.7.7-1.2.3-.3-.4 0-.8 0-1 0 0-.4 0-.4-.4 0-.6.7-.5 1-.5 0-.3.4-.8.5-.8 0 0 .6.5.6.8.2-.2 1 0 1 .4s-.5.5-.5.6c0 0 .6.3.3.7-.5.5-1 .1-1.2 0 0 .2-.1.6-.5.6-1.2 0-1.6-1.1-1.6-1.6v-.5h-.2a.2.2 0 0 1-.2-.2c0-.2.2-.3.3-.3l.4.2.2-.4V.6L6 .5c-.2 0-.4 0-.6.2-.2 0-.4.2-.8.1C4.2.8 4 .2 4 .2l.5.2c.3 0 1-.3 1.4-.2.3 0 .4.1.5.2H6.7C7 .2 7.4 0 8 0c.7 0 1.2.3 1.5.6 0 0 .2-.3.4-.3l.3.2v.1c0 .2 0 .3-.2.3-.1 0-.2 0-.2-.2v.4c.2.4.2.8.2.9l-.2.8c.1 0 .2 0 .3.2.1 0 .2.3 0 .4-.1.2-.3.2-.3 0l-.1-.2-.4.3c-.3.3-.8.5-1.6.5-1.1 0-1.8-.8-2.3-1.6H5c-.3 0-.5-.1-.5-.4 0-.1 0-.2.2-.3-.4-.6-1-1.5-2.2-1.5-1.8 0-2 1.2-2 2 0 .6.4 1.6 1.8 1.6.4 0 .7-.1 1-.3 0-.2 0-.3.2-.3h.1c.4-.4.6-.9.6-1.2 0-.5-.5-1.4-1.4-1.4-.4 0-.5.4-.5.6.2-.2.7-.5 1.2 0 .3.3-.3.7-.3.7s.5.1.5.6c0 .4-.8.5-1 .3 0 .4-.5.9-.6.9-.1 0-.6-.6-.6-.8-.2 0-.9 0-.9-.5s.3-.5.5-.5c-.1-.1-.4-.6 0-1s1 .1 1 .3c.1-.2.2-.7.7-.7C4 .5 4.4 1.6 4.4 2v.5l.3-.2s.2.1.2.3c0 .2-.2.3-.3.3l-.4-.2-.3.6.7-.1c.4 0 1 .4 1.2.4l.3-.1h.1s-.3.5-.8.5c-.6 0-.9-.5-1.2-.5zm-2-.8c.2 0 .5-.9.1-.9s-.2.8-.1.8zm5.7-.5s.2-.9-.1-.9c-.4 0-.2.9 0 .9z' style='fill:%23e3d1ad;fill-opacity:.501961'/%3e%3c/svg%3e"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  className={styles.control_item + " " + styles.video_control}
                  onClick={() => setActiveMediaCategory("Видеогалерея")}
                >
                  <button
                    className={
                      styles.control_button +
                      " " +
                      (ativeMediaCategory === "Видеогалерея"
                        ? styles.pressed
                        : "")
                    }
                    style={
                      ativeMediaCategory === "Видеогалерея"
                        ? {
                            backgroundColor: "rgba(174, 150, 124, 0.4)",
                            boxShadow:
                              "0 0 0 2px #ae967c, 0 0 0 4px #2e1005, 0 0 7px 3px #e3d1ad",
                          }
                        : {}
                    }
                    type="button"
                  >
                    <span>Видеогалерея</span>
                  </button>
                </div>
              </div>
            </div>

            {ativeMediaCategory === "Фотогалерея" ? (
              <div className={styles.photos_container}>
                {photo?.map((item, index) => (
                  <Image key={index} item={item} onClick={setCurrentPhoto} />
                ))}
              </div>
            ) : (
              <div className={styles.videos_container}>
                {videos?.map((item, index) => (
                  <div className={styles.video_item} key={index}>
                    <div
                      dangerouslySetInnerHTML={createMarkup(item.link)}
                    ></div>
                    <p>{item.title}</p>
                  </div>
                ))}
              </div>
            )}
          </section>
        </Container>
      </PageTemplate>
    </>
  );
};

export default Media;
